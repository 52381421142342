
import { Component, Prop } from "vue-property-decorator";
import RowMenu from "@/components/reusable/menu/RowMenu";
/**
 * All menu components use the prop "table" to decide what children components to render.
 *
 * Table === true means the menu is present in the parent table
 * Table === false means the menu is present on the edit screen (usually means hiding certain components, like <Edit>)
 */

@Component
export default class AttributeMenu extends RowMenu {
  @Prop() id!: number;
  @Prop() product_count!: number;
  @Prop() display_name!: string;
  @Prop({ default: true }) table!: boolean;
}
